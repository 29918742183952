<template>
  <div class="youtube-wrap">
    <div class="responsive-youtube align-center">
      <div @click="openModal" class="responsive-youtube-img">
        <img :src="img" :alt="[title]" class="y-video__media y-video__media--type--img">
        <div class="y-video__button">
          <svg viewBox="0 0 68 48" width="100%" height="100%">
            <path d="M66.5 7.7c-.8-2.9-2.5-5.4-5.4-6.2C55.8.1 34 0 34 0S12.2.1 6.9 1.6c-3 .7-4.6 3.2-5.4 6.1a89.6 89.6 0 0 0 0 32.5c.8 3 2.5 5.5 5.4 6.3C12.2 47.9 34 48 34 48s21.8-.1 27.1-1.6c3-.7 4.6-3.2 5.4-6.1C68 35 68 24 68 24s0-11-1.5-16.3z" class="y-video__button-shape"></path>
            <path d="M45 24L27 14v20" class="y-video__button-icon"></path>
          </svg>
        </div>
      </div>
    </div>
    <div class="align-center" style="padding: 15px 0;">{{title}}</div>
    <button @click="openModal" class="btn">Watch Video</button>
    <sweet-modal modal-theme="dark" overlay-theme="dark" ref="modal" v-on:close="closeVid">
      <div class="responsive-iframe">
      <iframe v-if="ytVisible" width="560" height="315" :src="ytvid" frameborder="0" allowfullscreen ref="ytvideo"></iframe>
      </div>
    </sweet-modal>
  </div>
</template>

<script>
import { SweetModal } from 'sweet-modal-vue'

export default {
  name: 'YouTube',
  components: {
    SweetModal
  },
  data: function() {
    return {
      ytVisible : false
    }
  },
  methods: {
    openModal() {
      this.$refs.modal.open()
      this.ytVisible = true
    },
    closeVid() {
      this.ytVisible = false
    }
  },
  props: [
    'videoid',
    'title',
    'img'
  ],
  computed: {
    ytvid() {
      return `https://www.youtube.com/embed/${encodeURIComponent(this.videoid)}?version=3&enablejsapi=1`;
    },
    ytwebp() {
      return `https://i.ytimg.com/vi_webp/${encodeURIComponent(this.videoid)}/maxresdefault.webp`;
    },
    ytimg() {
      return `https://i.ytimg.com/vi/${encodeURIComponent(this.videoid)}/maxresdefault.jpg`;
    }
  }
}
</script>
<style lang="scss">
  .responsive-youtube {
    position: relative;
    display: block;
    width: 100%;
    max-width: 800px;
    margin: auto;
    background: none;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2);
    @media(max-width:980px) {
      margin-bottom: 30px;
    }
    .responsive-youtube-img {
      display: block;
      position: relative;

      &:hover {
        cursor: pointer;
        .y-video__button {
          cursor: pointer;
          opacity: 1;
        }
        img {
          opacity: .7;
        }
      }
    }
    .y-video {
      position: relative;
      &:hover {
        cursor: pointer;
        .y-video__button {
          opacity: .95;
        }
      }
    }
    .y-video__button {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100px;
      display: block;
      background: rgba(0,0,0,0);
      border: none;
      transform: translate(-50%, -50%);
      opacity: .8;
      .y-video__button-shape {
        fill: #1e5dbc;
      }
      .y-video__button-icon {
        fill: #fff; 
      }
      @media(max-width:980px) {
          margin-bottom: 30px;
      }
    } 
    img {
      position: relative;
      display: block;
      top: 0;
      left: 0;
      width: 100%;
      max-width: none;
    }
  }
</style>