import Vue from 'vue';
import Vuex from 'vuex';
import firebase from 'firebase/app';
import 'firebase/firestore'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		items: null,
		rusers: null,
		cusers: null,
		users: null,
		content: null,
		authorized: false,
		resources: null,
		painpoints: null,
		limited: null,
		limitedContent: null
	},
	getters: {
		getRegistered: state => {
			return state.rusers
		},
		getCreated: state => {
			return state.cusers
		},
		setItems: state => {
			return state.items
		},
		setResources: state => {
			return state.resources
		},
		getPainpoints: state => {
			return state.painpoints
		},
		getLimited: state => {
			return state.limited
		},
		setLimited: state => {
			return state.limitedContent
		},
		setTimer: state => {
			return state.timer
		},
		setAuth: state => {
			return state.authorized
		},
		setContent: state => {
			return state.content
		},
		getContent: (state) => (id) => {
			return state.content.find(item => item.id === id)
		}

	},
	mutations: {
		getRegistered: state => {
			
			firebase
			.database()
			.ref("users")
			.on("value", function(snapshot) {
			    let rusers = []
			    snapshot.forEach(data => {
			      rusers.push({id: data.key, email: data.val().email})
			    })
			    state.rusers = rusers
			})

 		},
 		getCreated: state => {
			
			firebase
			.database()
			.ref("users")
			.orderByChild('created').equalTo('true')
			.on("value", function(snapshot) {
			    let cusers = []
			    snapshot.forEach(data => {
			      cusers.push({id: data.key, email: data.val().email, created: data.val().created})
			    })
			    state.cusers = cusers
			})

 		},
		setItems: state => {
			let items = []

			firebase
			.firestore()
			.collection("events")
			.orderBy('date')
			.onSnapshot((snapshot) => {
				items = []
				snapshot.forEach((doc) => {
					items.push({id: doc.id, date: doc.data().date, speaker: doc.data().speaker, title: doc.data().title, url: doc.data().url})
				})

				state.items = items
			})
		},
		setResources: state => {
			let resources = []

			firebase
			.firestore()
			.collection("resources")
			.orderBy('order')
			.onSnapshot((snapshot) => {
				resources = []
				snapshot.forEach((doc) => {
					resources.push({id: doc.id, type: doc.data().type, order: doc.data().order, title: doc.data().title, preview: doc.data().preview, url: doc.data().url})
				})

				state.resources = resources
			})
		},
		getLimited: state => {
			let limited = []
			let Current = new Date();
			let TS = Current.getTime()/1000;
			firebase
			.firestore()
			.collection("limited")
			.orderBy('expiry')
			.where('expiry', '>', TS)
			.onSnapshot((snapshot) => {
				limited = []
				snapshot.forEach((doc) => {
					limited.push({id: doc.id, title: doc.data().title, year: doc.data().year,month: doc.data().month, day: doc.data().day, hour: doc.data().hour, min: doc.data().min, expiry: doc.data().expiry, img: doc.data().img, vid: doc.data().vid, desc: doc.data().desc})
				})

				state.limited = limited
			})
		},
		getPainpoints: state => {
			let painpoints = []
			firebase
			.firestore()
			.collection("painpoints")
			.orderBy('order')
			.onSnapshot((snapshot) => {
				painpoints = []
				snapshot.forEach((doc) => {
					painpoints.push({id: doc.id, slug: doc.data().slug, icon: doc.data().icon, img: doc.data().img, title: doc.data().title, order: doc.data().order, content: doc.data().content})
				})

				state.painpoints = painpoints
			})
		},
		setLimited: state => {
			let limitedContent = []
			firebase
			.firestore()
			.collection("limited")
			.orderBy('expiry')
			.onSnapshot((snapshot) => {
				limitedContent = []
				snapshot.forEach((doc) => {
					limitedContent.push({id: doc.id, title: doc.data().title, year: doc.data().year,month: doc.data().month, day: doc.data().day, hour: doc.data().hour, min: doc.data().min, expiry: doc.data().expiry, img: doc.data().img, vid: doc.data().vid, desc: doc.data().desc})
				})

				state.limitedContent = limitedContent
			})
		},
		setContent: state => {
			let content = []

			firebase
			.firestore()
			.collection("limited")
			.onSnapshot((snapshot) => {
				content = []
				snapshot.forEach((doc) => {
					content.push({ desc: doc.data().desc})
				})

				state.content = content
			})
		},
		setAuth (state, value) {
			state.authorized = value
		},
		setTimer (state, value) {
			state.timer = value
		}
	},
	actions: {
		getRegistered: context => {
			context.commit('getRegistered');
		},
		getCreated: context => {
			context.commit('getCreated');
		},
		setItems: context => {
			context.commit('setItems');
		},
		setResources: context => {
			context.commit('setResources');
		},
		getLimited: context => {
			context.commit('getLimited');
		},
		getPainpoints: context => {
			context.commit('getPainpoints');
		},
		setLimited: context => {
			context.commit('setLimited');
		},
		setContent: context => {
			context.commit('setContent');
		},
		setAuth: context => {
			context.commit('setAuth');
		},
		setTimer: context => {
			context.commit('setTimer');
		}
	}
})