import firebase from 'firebase/app'
import 'firebase/auth'

import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/home',
    name: 'Home',
    component: Home,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/edit',
    name: 'editLimited',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/editLimited.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/add',
    name: 'addLimited',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/addLimited.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/diy-coaching/:slug',
    name: 'viewPainpoint',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/viewPainpoint.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/add-painpoint',
    name: 'addPainpoint',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/addPainpoint.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/edit-painpoint',
    name: 'editPainpoint',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/editPainpoint.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/',
    name: 'Login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue'),
    meta: {
      login: true
    }
  },
  {
    path: '/forgot',
    name: 'Forgot',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Forgot.vue')
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Dashboard.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/setup',
    name: 'Setup',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Setup.vue')
  },
  {
    path: '/connect',
    name: 'Connect',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/connectHome.vue'),
    meta: {
      requiresEmbed: true
    }
  },
  {
    path: '/diy/:slug',
    name: 'ConnectPainpoint',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/connectPainpoint.vue'),
    meta: {
      requiresEmbed: true
    }
  }
]



const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    firebase.auth().onAuthStateChanged((user) => {
      
      if (!user) {
        next('/')
      } else if(user && to.path == "/dashboard") {
        var emailAddress = user.email;
        if(!emailAddress.endsWith('@connectideal.com')) {
          next('/home')  
        } else {
          next()
        }
        
      } else {
        next()
      }
    });

  } else if (to.matched.some(record => record.meta.requiresEmbed)) {
    //console.log(to.query.connect)
    let parentHost = document.location.ancestorOrigins[0];
    //console.log("parentHost: "+ parentHost );
    //console.log(document.location);
    switch (parentHost) {
      case 'file://':
        next()
        break;
      case 'http://localhost:8080':
        next()
        break;
      case 'https://app.connectideal.com':
        next()
        break;
      default:
        next('/home');
    }
    
  } else {
    let parentHost =document.location.origin;
    console.log(document.location.origin);
    next() // make sure to always call next()!
    
  }
  
})

export default router
