<template>
  <div id="app">
    <header class="header-wrap">
      <div class="header">
        <router-link to="/home" class="logo-wrap">
          <img src="/images/ConnectIdealLogo.webp" alt="Connect Ideal" class="logo" />
          <span class="sr-only">Connect Ideal Resources Page</span>
        </router-link>
        <div class="nav-links">
          <router-link v-if="this.isAdmin" to="/home" class="nav-btn">
            <Icon name="checklist" class="nav-icon" />
            Resources
          </router-link>
          <router-link v-if="this.isAdmin" to="/dashboard" class="nav-btn">
            <Icon name="code_single" class="nav-icon" />
            Dashboard
          </router-link>
          <button v-if="this.user" @click="logOut" class="nav-btn">
           <Icon name="sign_out" class="nav-icon" />
           Sign Out
          </button>
          <button v-if="this.user" @click="toggle" class="nav-btn menu-toggle" :class="{active:isActive}">
           <Icon name="lines" class="nav-icon" v-if="isActive !== true" />
           <Icon name="close" class="nav-icon" v-if="isActive === true" />
           Menu
          </button>
        </div>
      </div>
    </header>
    <div class="header-spacer"></div>
    <div :class="[(this.user === true ? 'body_wrap' : '') ,(this.currentroute === 'Home' ? padclass : ''),(this.currentroute === 'WeeklyCoach' ? padclass : ''),(this.currentroute === 'viewPainpoint' ? padclass : ''),(this.$route.name === 'Connect' ? 'body_wrap padding' : ''),(this.$route.name === 'ConnectCoach' ? 'body_wrap padding' : ''),(this.$route.name === 'ConnectPainpoint' ? 'body_wrap padding' : '')]">
        <div v-if="this.user || this.isAdmin || this.$route.name === 'Connect' || this.$route.name === 'ConnectCoach' || this.$route.name === 'ConnectPainpoint'" class="sidebar_wrap" :class="[(isActive ? 'active' : ''), (this.currentroute === 'Home' ? 'show' : ''), (this.currentroute === 'WeeklyCoach' ? 'show' : ''),(this.currentroute === 'viewPainpoint' ? 'show' : ''),(this.$route.name === 'Connect' ? 'show' : ''),(this.$route.name === 'ConnectCoach' ? 'show' : ''),(this.$route.name === 'ConnectPainpoint' ? 'show' : '')]">
          <nav>
            <template v-if="this.currentroute === 'Home' || this.$route.name === 'Connect'">
              <button @click="goto('app')" class="nav-link"><span>Home</span></button>
              <div class="nav-link no-link"><span>Limited Time Resources</span></div>
              <button @click="goto('team_training')" v-if="this.$store.getters.getLimited && this.$store.getters.getLimited.length > 0" class="nav-sublink"><span>Team Training</span></button>
              <button @click="goto('coaching_diy')" class="nav-link"><span>Coaching DIY</span></button>
              <button @click="goto('upcoming_events')" class="nav-link"><span>Upcoming Events</span></button>
              
            </template>
            <template v-else>
              <template v-if="this.$route.name === 'ConnectPainpoint'">
                <router-link :to="{name: 'Connect', query: {scrollto: 'app'}}" class="nav-link"><span>Home</span></router-link>
                <div class="nav-link no-link"><span>Limited Time Resources</span></div>
                <router-link :to="{name: 'Connect', query: {scrollto: 'team_training'}}" class="nav-sublink"><span>Team Training</span></router-link>
                <router-link :to="{name: 'Connect', query: {scrollto: 'coaching_diy'}}" class="nav-link"><span>Coaching DIY</span></router-link>
                <router-link :to="{name: 'Connect', query: {scrollto: 'upcoming_events'}}" class="nav-link"><span>Upcoming Events</span></router-link>
              </template>
              <template v-else>
                <router-link :to="{name: 'Home', query: {scrollto: 'app'}}" class="nav-link"><span>Home</span></router-link>
                <div class="nav-link no-link"><span>Limited Time Resources</span></div>
                <router-link :to="{name: 'Home', query: {scrollto: 'team_training'}}" class="nav-sublink"><span>Team Training</span></router-link>
                <router-link :to="{name: 'Home', query: {scrollto: 'coaching_diy'}}" class="nav-link"><span>Coaching DIY</span></router-link>
                <router-link :to="{name: 'Home', query: {scrollto: 'upcoming_events'}}" class="nav-link"><span>Upcoming Events</span></router-link>
              </template>
            </template>
          </nav>
        </div>
      <transition  name="fade">
        <router-view class="content_wrap"  />
      </transition>
    </div>
  </div>
</template>
<script>
  import firebase from 'firebase/app';
  import 'firebase/auth';
  import 'firebase/database';

  import Icon from '@/components/Icons.vue'


  export default {
  metaInfo: {
    title: 'Connect Ideal Resources'
  },
  data: function() {
    return {
      loggedIn : '',
      user: null,
      isAdmin: false,
      items: [],
      email: '',
      isActive: false,
      padclass: 'padding'
    }
  },
  components : {
    Icon
  },
  created() {
    this.$store.dispatch('getPainpoints')
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.user = true;
        this.email = user.email;
        var emailAddress = user.email;
        if (emailAddress.endsWith('@connectideal.com')) {
          this.isAdmin = true
          //console.log("Admin "+ this.isAdmin)
        } else {
          this.isAdmin = false
        }
      } else {
        this.user = null;
        this.isAdmin = false
      }
    });

  },
  mounted: function() {
    
    this.$watch(
      () => this.$route.query,
      () => {
        this.isActive = false;
        let scrollto = this.$route.query.scrollto
        if(typeof scrollto !== "undefined") {          
          setTimeout(() => this.goto(scrollto) , 1500);
        }
      }
    )
  },
  computed: {
    currentroute() {
      return this.$route.name;
    }
  },
  methods: {
    logOut() {
      firebase.auth().signOut().then(() => {
        firebase.auth().onAuthStateChanged(() => {
          this.$router.go()
        })
      })
    },
    toggle() {
      this.isActive = this.isActive ? false : true;
    }
  }
}
</script>