import '@/scss/main.scss'
import '@/assets/fa/fontawesome.scss'
import '@/assets/fa/regular.scss'
import '@/assets/fa/light.scss'
import '@/assets/fa/solid.scss'
import '@/assets/fa/brands.scss'

import VueMeta from 'vue-meta';

import Vue from 'vue'
import App from './App.vue'
import router from './router/index.js'
import store from './store/index.js'
import firebase from 'firebase/app';
import 'firebase/auth';

import Toasted from 'vue-toasted';

import persistentState from 'vue-persistent-state'

const config = {
  apiKey: process.env.VUE_APP_apikey,
  authDomain: process.env.VUE_APP_auth_domain,
  databaseURL: process.env.VUE_APP_database_url,
  projectId: process.env.VUE_APP_project_id,
  storageBucket: process.env.VUE_APP_storage_bucket,
  messagingSenderId: process.env.VUE_APP_messaging_sender_id,
  appId: process.env.VUE_APP_app_id,
  measurementId: process.env.VUE_APP_measurement_id

};

let initialState = {
  persisted: {
    str: 'dead'
  }
}
const MyPlugin = {
  install(Vue, options) {
    Vue.prototype.goto = (id) => {
      var element = document.getElementById(id);
      var top = element.offsetTop - 50;
      window.scroll({ top: top, behavior: 'smooth' });
      this.isActive = false;
      console.log(element.offsetTop);
    }
  },
}
Vue.use(MyPlugin)

firebase.initializeApp(config);

Vue.use(Toasted)

Vue.use(VueMeta);

Vue.use(persistentState, initialState)

Vue.config.productionTip = false


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
